<template>
  <validation-observer
    ref="refFormObserver"
  >
    <b-form
      class="list-view product-checkout"
      @submit.prevent="submit()"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Formas de pagamento
          </h5>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="Selected"
              name="some-radios"
              value="3"
            >
              Cartão de Crédito
            </b-form-radio>
            <b-form-radio
              v-model="Selected"
              name="some-radios"
              value="2"
            >
              Boleto/Pix
            </b-form-radio>
          </div>
        </b-col>

        <div v-if="Selected==='3'">
          <b-col md="12">
            <b-overlay
              :show="loading"
              rounded="sm"
            >
              <validation-provider
                v-slot="{ errors }"
                name="cardNumber"
                rules="required|numeric"
              >
                <b-form-group
                  label="Numero do cartão"
                  label-for="cardNumber"
                >
                  <the-mask
                    id="cardNumber"
                    v-model="card.number"
                    class="form-control"
                    :mask="['#### #### #### ####', '#### #### #### #### #']"
                    placeholder="1561 5165 5165 1561"
                    :state="errors.length > 0 ? false : null"
                    :style="errors.length > 0 ? 'border-color: red;' : ''"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-row>
                <b-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="valid"
                    rules="required"
                  >
                    <b-form-group
                      label="Validade"
                      label-for="valid"
                    >
                      <the-mask
                        id="valid"
                        v-model="card.valid"
                        class="form-control"
                        :mask="['##/##']"
                        :masked="true"
                        placeholder="Mês/Ano"
                        :state="errors.length > 0 ? false : null"
                        :style="errors.length > 0 ? 'border-color: red;' : ''"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="valid"
                    rules="required"
                  >
                    <b-form-group
                      label="Código"
                      label-for="cvv"
                    >
                      <b-form-input
                        id="cvv"
                        v-model="card.cvv"
                        placeholder="546"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-overlay>
            <b-row>
              <span v-if="loading">Aguarde... <br> Estamos verificando as opções de parcelamento!</span>
            </b-row>
            <b-button
              block
              variant="primary"
              style="margin: 5px 0 10px 0"
              :disabled="loading"
              @click="getTokenAndInstallments()"
            >
              Ver parcelamento
            </b-button>
            <!-- <b-button
              block
              variant="success"
              style="margin: 5px 0 10px 0"
            >
              Finalizar pagamento
            </b-button> -->
          </b-col>
        </div>

        <b-col
          v-if="Selected==='2'"
          cols="12"
          md="12"
        >
          <b-row style="width:100%;">
            <b-col md="7">
              <div>
                <ol style="list-style:none;">
                  <li>1 Gere o boleto</li>
                  <li>2 Aguarde o processo</li>
                  <li>3 Baixe o boleto ou escaneie o QR Code pix</li>
                  <li>4 Seu curso será liberado automaticamente após a confirmação do pagamento.</li>
                </ol>
              </div>
              <div v-if="payment">
                <div>Código de barras:</div>
                <div>{{ payment.data.barcode }}</div>
              </div>
              <div v-if="loadingBoleto">
                Estamos gerando o boleto. <br>
                Aguarde...
              </div>

              <b-button
                v-if="!payment"
                variant="success"
                style="margin: 5px 0 10px 0"
                :disabled="loadingBoleto"
                @click="getBoleto()"
              >
                <b-overlay
                  :show="loadingBoleto"
                  rounded="sm"
                >
                  Finalizar com boleto
                </b-overlay>
              </b-button>
              <a
                v-if="payment"
                class="btn btn-primary"
                style="margin: 5px 0 10px 0"
                :href="payment.data.pdf.charge"
                :download="`gerencianet-boleto-${payment.data.charge_id}`"
                target="_blank"
              >
                Imprimir boleto
              </a>
            </b-col>

            <b-col
              v-if="payment"
              cols="12"
              md="5"
            >
              <b-row>
                <b-col md="7">
                  Pagamento com pix: <br>
                  {{ payment.data.pix.qrcode }}
                </b-col>
                <b-col md="5">
                  <div>
                    <img :src="payment.data.pix.qrcode_image">
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="$emit('previous-step')"
      >
        Voltar
      </b-button>

      <!-- <b-button
        variant="primary"
        class="float-right"
        @click="submit()"
      >
        Finalizar compra
      </b-button> -->
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  alpha, alpha_spaces, numeric, required
} from 'vee-validate/dist/rules'
import PaymentServices from './paymentServices'
import creditCardType from 'credit-card-type'
import { mapActions } from 'vuex'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('alpha', {
  ...alpha,
  message: 'Apenas letras nesse campo.',
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Apenas letras e números nesse campo.',
})

extend('numeric', {
  ...numeric,
  message: 'Apenas números nesse campo.',
})

export default {
  components: {
    BRow,
    BCol,

    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      Selected: null,
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      card: {
        brand: '', // bandeira do cartão
        number: '', // número do cartão
        cvv: '', // código de segurança
        valid: ''
        // expiration_month: '', // mês de vencimento
        // expiration_year: '', // ano de vencimento
      },
      token: '',
      customer: {
        name: '',
        cpf: '',
        email: '',
        phone_number: '',
        birth: '',
        address: {
          street: '',
          number: '',
          neighborhood: '',
          zipcode: '',
          city: '',
          state: ''
        }
      },
      items: [],
      installments: {},
      installmentsOptions: null,
      toGetInstallments: {
        brand: '',
        totalValue: 0
      },
      loading: false,
      loadingBoleto: false,
      payment: null
    }
  },
  computed: {
    customerState() {
      return this.$store.state.payment.customer
    },
    itemsState() {
      return this.$store.state.payment.items
    },
    cardBrand() {
      return this.card.brand
    },
    cardNumb() {
      return this.card.number
    },
  },
  watch: {
    cardNumb() {
      const card = creditCardType(this.cardNumb)
      this.card.brand = card[0].type
      this.toGetInstallments.brand = card[0].type
    },
  },
  async mounted() {
    this.itemsToSave = JSON.parse(localStorage.getItem('items'))
    console.log(this.itemsToSave)
    this.itemsToSave.forEach(item => {
      this.items.push({
        name: item.name,
        amount: item.amount, // quantidade
        value: item.value
      })
    })

    await this.items.forEach(item => {
      this.toGetInstallments.totalValue = item.value + this.toGetInstallments.totalValue
    })

    console.log(this.toGetInstallments.totalValue)
  },
  methods: {
    ...mapActions('payment', [
      'ActionSetToken',
      'ActionSetToGetInstallments',
      'ActionsSetCustomer',
      'ActionSetAddress',
      'ActionSetPayment',
      'ActionSetItems',
    ]),
    formatPhoneNumber(number) {
      if (number.indexOf(' ') >= 0) {
        const phoneNumber = number.split(' ')
        console.log(`${phoneNumber[1]}${phoneNumber[2]}${phoneNumber[3]}`)
        return `${phoneNumber[1]}${phoneNumber[2]}${phoneNumber[3]}`
      }
      return number
    },
    // eslint-disable-next-line consistent-return
    formatDate(date, toLang) {
      const dateDefault = date

      if (date) {
        if (toLang === 'US') {
          const XXI = dateDefault.split('/')
          const dateUS = `${XXI[2]}-${XXI[1]}-${XXI[0]}`
          return dateUS
        } if (toLang === 'PT') {
          const XXI = dateDefault.split('-')
          const datePT = `${XXI[2]}-${XXI[1]}-${XXI[0]}`
          return datePT
        }
      }
    },
    async getTokenAndInstallments() {
      this.loading = true
      PaymentServices().startPayment()
      await PaymentServices().getPaymentToken(this.card).then(async res => {
        this.token = res.data.payment_token
        await this.ActionSetToken(this.token)
        await this.ActionSetToGetInstallments(this.toGetInstallments)
        this.loading = false

        window.location.href = '/administrador/usuarios/finalizar-pagamento'
      })
    },
    async getBoleto() {
      this.loadingBoleto = true
      this.formatPhoneNumber(this.customerState.phone_number)

      PaymentServices().getBoletoAssociado({
        items: this.items,
        itemsToSave: this.itemsToSave,
        customer: {
          name: this.customerState.name,
          cpf: this.customerState.cpf,
          email: this.customerState.email,
          phone_number: this.formatPhoneNumber(this.customerState.phone_number),
          birth: this.formatDate(this.customerState.birth, 'US'),
        }
      }).then(api => {
        this.payment = api.data.response.payment
        console.log(this.payment)
        this.loadingBoleto = false
        const arr = []
        localStorage.setItem('items', JSON.stringify(arr))
        this.ActionSetItems([])
        this.ActionSetCustomer({
          name: '',
          cpf: '',
          email: '',
          phone_number: '',
          birth: '',
        })
        this.ActionSetAddress({
          street: '',
          number: '',
          neighborhood: '',
          zipcode: '',
          city: '',
          state: '',
        })
      })
    },
  }
}
</script>

<style scoped>
button.btn-next {
  display: none !important;
  color: #000 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
